@import './box';
@import './colors';

@mixin button {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 4rem;
  height: 4rem;
  background: $darkBlue;
  color: white;
  padding: 0 $basePadding;
  border-radius: 3px;
  border: 0;

  &:link,
  &:visited {
    color: white;
  }

  &:hover,
  &:active {
    background: darken($darkBlue, 10%);
    cursor: default;
    color: white;
  }

  &:disabled {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;

    .Loading {
      align-self: center;
    }

    svg {
      width: 2rem;
      height: 2rem;
      path,
      rect {
        fill: rgba(white, 0.9);
      }
    }
  }

  &.Button--secondary {
    background: $lightGray;
    color: $black;
    border: 0;
    font-size: 1.6rem;

    &:hover,
    &:active,
    &:focus {
      outline: 0;
      background: $darkBlue;
      color: white;
    }
  }

  &.Button--small {
    color: #333;
    display: flex;
    align-self: center;
    justify-content: center;
    // padding: 0 0.5*$basePadding;
    font-size: 1.4rem;
    line-height: 3rem;
    height: 3rem;
    min-width: 3rem;

    i {
      align-self: center;
      font-size: 1.6rem;
    }

    &:hover {
      background: $darkBlue;
      color: white;
    }
  }

  &.Button--small--text {
    display: inline-block;
  }
}

form {
  &.has-required-fields {
    &:after {
      content: '* denotes required information';
      display: block;
      padding-bottom: $basePadding;
      color: $red;
      font-size: 1.4rem;
    }
  }

  .formRow {
    margin-bottom: $basePadding;

    .flexRow,
    &.flexRow { /* yes, both selectors */
      display: flex;
      align-items: center;

      button {
        margin-right: 1rem;
      }

      &-Column {
        flex-basis: 50%;
      }
    }

    &--buttons {
      margin-top: 4 * $basePadding;
    }
  }

  .formError {
    margin-bottom: $basePadding;
    background: $red;
    color: white;
    padding: $basePadding;
  }

  .fieldError {
    color: $red;
    padding-lefT: 0.5 * $basePadding;
  }

  .colorPicker { 
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    width: 100%;
    height: 50px;
  }

  label {
    display: inline-block;
    font-size: 1.4rem;
    padding-right: 0.5rem;
    color: #333;
    margin-bottom: 0.5rem;

    .tip {
      font-style: italic;
      color: $gray;
      font-size: 1.4rem;
      font-weight: normal;
    }

    &.required {
      &:after {
        content: '*';
        display: inline-block;
        padding-left: 0.5rem;
        color: $red;
      }
    }

    &.primary {
      font-size: 1.6rem;
      font-weight: bold;
      display: block;
    }

    &.hasImage {
      display: inline-block;
      border: 2px solid $lightGray;
      padding: 0.5 * $basePadding;
      width: 214px;
      margin-right: $basePadding;

      &:hover {
        background: $lighterGray;
      }

      &.selected,
      &.selected:hover {
        border: 2px solid darken($lightBlue, 10%);
        background: $lightBlue;
      }
      input[type=radio] {
        display: none;
      }
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="number"] {
    font-size: 1.6rem;
    line-height: 200%;
    display: block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border: 1px solid $lightGray;

    &.wide {
      width: 100%;
    }

    &.inline {
      display: inline-block;
      width: auto;
    }
  }

  input[type="radio"] {
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
  }

  input[type="submit"] {
    @include button;
  }

  select {
    width: auto;
    font-size: 1.6rem;
    padding: 1rem;
    border: 1px solid $lightGray;
  }
}

button,
a.Button {
  @include button;
  cursor: pointer;
}

.chrome-picker {
  box-shadow: none !important;
  border: 1px solid $lightGray;
}
