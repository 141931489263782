$darkestBlue: rgb(4, 17, 24);
$darkBlue: rgb(45, 93, 121);
$lightBlue: rgb(150, 205, 232);
$red: rgb(223, 96, 92);
$black: #111;
$gray: #999;
$lightGray: #ddd;
$lighterGray: #eee;
$mediumGray: #999;
$darkGray: #666;
$darkestGray: #222;
$green: rgb(19, 141, 117);
$orange: orange;
$yellow: gold;
$purple: purple;
$pink: pink;