html {
  font-size: 62.5%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
}

h1 {
  font-size: 2rem;
  margin-top: 0;
}

h2{
  font-size: 1.6rem;
  margin-top: 0;
}

dl {
  dt {
    display: inline-block;
    width: 12rem;
  }

  dd {
    display: inline-block;
    margin: 0;
  }
}
