@import './../../assets/sass/box';
@import './../../assets/sass/colors';
@import './../../assets/sass/forms';
@import './../../assets/sass/icons';
@import './../../assets/sass/typography';

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
  font-family: 'Roboto';
}

#root,
#root > div {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.ScorbitDashboard {
  height: 100%;

  &--loading {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;

    .Loading {
      svg {
        width: 10rem;
        height: 10rem;
      }
    }
  }
}

a:link,
a:visited {
  color: $darkBlue;
  text-decoration: none;
}

a:hover,
a:active {
  color: darken($darkBlue, 20%);
}
